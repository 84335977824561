import { useState } from 'react';
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Spinner,
  Accordion,
  ListGroup,
  Alert,
  Card
} from 'react-bootstrap';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { 
  faBed,
  faCar,
  faShower,
  faDrawPolygon,
  faLink
} from '@fortawesome/free-solid-svg-icons';
import {NotificationContainer, NotificationManager} from 'react-notifications';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-notifications/lib/notifications.css';

function App() {
  const [cep, setCep] = useState();
  const [estate_kind, setEstateKind] = useState('apartments');
  const [size, setSize] = useState(120);
  const [address, setAddress] = useState();
  const [neighbourhood, setNeighbourhood] = useState();
  const [city, setCity] = useState();
  const [uf, setUf] = useState();

  const [loading, setLoading] = useState(false);
  const [samples, setSamples] = useState([]);
  const [result, setResult] = useState();
  const [error, setError] = useState(false);
  const [loaded, setLoaded] = useState(false);

  const search = async () => {
    if (!address) {
      NotificationManager.warning('Informe um endereço');

      return;
    }
    
    if (!city) {
      NotificationManager.warning('Informe uma cidade');

      return;
    }
    
    if (!uf) {
      NotificationManager.warning('Informe um estado');

      return;
    }
    
    if (!size) {
      NotificationManager.warning('Informe a área');

      return;
    }

    setSamples([]);
    setResult(null);
    setLoading(true);
    setError(false);
    setLoaded(false);

    const response = await axios.post(
      'https://avm.kapputo.com/evaluate',
      {
        "estate": {
          "size": parseInt(size),
          "bathrooms":"","rooms":"","apartments":"","parking":""
        },
        "address": {
          "address": address + (neighbourhood ? ", " + neighbourhood : ""),    
          "city" : city,
          "state": uf
        },
        "transfer_kind": "sell",
        "estate_kind": estate_kind,
        //"max_distance" : 1000,
        "api_key" : "0a70b04385f162fb96bb917144c2e8a61a7a8f4f",
        "email" : "bruno.guedes@facilite.co"
      },
      {
        headers : {
          'Content-type' : "application/json"
        }
      }
    )
    .catch(error => {
      setError(true);
    });

    if (response) {
      if (response.status == 200) {
        const body = response.data;
  
        if (body) {
          if (body.data) {
            setSamples(body.data.samples);
          }

          if (body.data) {
            setResult(body.data.result);
          }
        }
      }
    }

    setLoaded(true);   

    setLoading(false);
  }

  const searchCep = (cep) => {
    buscaCEP(cep)
    .then(response => {
      setUf(response.uf);
      setCity(response.localidade);
      setNeighbourhood(response.bairro);
      setAddress(response.logradouro);
    });
  }

  const buscaCEP = async (cep) => {
    const cepFormatted = cep.toString().replace(/\-/g, '').replace(/\./g, '');

    const response = await axios.get(
      'https://viacep.com.br/ws/'+cepFormatted+'/json/'
    );

    if (response.status == 200) {
      return response.data;
    }
  }

  let samplesHtml =  "", resultHtml = "";

  if (samples) {
    samplesHtml = <Accordion>
      { samples.map(
        (sample, index) => 
        <Accordion.Item eventKey={index} key={index}>
          <Accordion.Header>{ sample.address }, { sample.neighbourhood }</Accordion.Header>
          <Accordion.Body>
            <ListGroup>
              <ListGroup.Item>Rua: <strong>{ sample.address }</strong></ListGroup.Item>              
              <ListGroup.Item>Bairro: <strong>{ sample.neighbourhood }</strong></ListGroup.Item>              
              <ListGroup.Item><FontAwesomeIcon icon={faBed} />  <strong>{ sample.rooms } quarto{ sample.rooms > 1 ? "s" : "" }</strong></ListGroup.Item>              
              <ListGroup.Item><FontAwesomeIcon icon={faCar} />  <strong>{ sample.parking } vaga{ sample.parking > 1 ? "s" : "" }</strong></ListGroup.Item>              
              <ListGroup.Item><FontAwesomeIcon icon={faShower} />  <strong>{ sample.bathrooms } banheiro{ sample.bathrooms > 1 ? "s" : "" }</strong></ListGroup.Item>              
              <ListGroup.Item><FontAwesomeIcon icon={faDrawPolygon} />  <strong>{ sample.size } m2 </strong></ListGroup.Item>              
              <ListGroup.Item><FontAwesomeIcon icon={faLink} /> <a href={sample.url} target="_blank">Link</a></ListGroup.Item>              
            </ListGroup>
          </Accordion.Body>

        </Accordion.Item>
      ) }
    </Accordion>

    if (samples.length == 0 && loaded) {
      samplesHtml = <Alert variant='info'>Nenhum registro encontrado</Alert>;
    }
  }

  if (result) {
    resultHtml = <Row>
      <Col xs={12} md={4}>
        <Card>
          <Card.Body>
            <Card.Title>Valor médio do imóvel</Card.Title>
            <Card.Text>
              { new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(result.stats.value.value_sample_mean) }
            </Card.Text>
          </Card.Body>
        </Card>        
      </Col>
      <Col xs={12} md={4}>
        <Card>
          <Card.Body>
            <Card.Title>Preço médio do m2</Card.Title>
            <Card.Text>
              { new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(result.value / size) }
            </Card.Text>
          </Card.Body>
        </Card>                
      </Col>
      <Col xs={12} md={4}>
        <Card>
          <Card.Body>
            <Card.Title>Qtd. amostras</Card.Title>
            <Card.Text>
              { result.stats.value.sample_size }
            </Card.Text>
          </Card.Body>
        </Card>                        
      </Col>
    </Row>
  }

  return (
    <Container>
      <h1>Hauzs</h1>

      { error ? <Alert variant='danger'>Ocorreu um erro</Alert> : ""}

      <Row>
        <Col xs={12} md={2}>
          <Form.Group className="mb-3">
            <Form.Label>Tipo de imóvel</Form.Label>
            <Form.Select aria-label="Tipo de imóvel" value={estate_kind} onChange={(ev) => setEstateKind(ev.target.value)}>
              <option value="apartments">Apartamento</option>
              <option value="house">Casa</option>
              <option value="comercial">Comercial</option>
              <option value="terreno">Terreno</option>
            </Form.Select>
          </Form.Group>          
        </Col>
        <Col xs={12} md={2}>
          <Form.Group className="mb-3">
            <Form.Label>Área</Form.Label>
            <Form.Control
              placeholder=''
              value={size}
              onChange={(ev) => setSize(ev.target.value)}
              type='number'
              />
          </Form.Group>          
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={4}>
          <Form.Group className="mb-3">
            <Form.Label>CEP</Form.Label>
            <Form.Control
              placeholder='Digite um CEP'
              onChange={(ev) => setCep(ev.target.value)}
              onBlur={(ev) => searchCep(cep)}
              type='number'
              />
          </Form.Group>
        </Col>        
        <Col xs={12} md={3}>
          <Form.Group className="mb-3">
            <Form.Label>Estado</Form.Label>
            <Form.Control
              value={uf}
              onChange={(ev) => setUf(ev.target.value)}
              />
          </Form.Group>
        </Col>        
        <Col xs={12} md={5}>
          <Form.Group className="mb-3">
            <Form.Label>Cidade</Form.Label>
            <Form.Control
              value={city}
              onChange={(ev) => setCity(ev.target.value)}
              />
          </Form.Group>
        </Col>        
        <Col xs={12} md={12}>
          <Form.Group className="mb-3">
            <Form.Label>Endereço</Form.Label>
            <Form.Control
              value={address}
              onChange={(ev) => setAddress(ev.target.value)}
              />
          </Form.Group>
        </Col>        
        <Col xs={12} md={12}>
          <Form.Group className="mb-3">
            <Form.Label>Bairro</Form.Label>
            <Form.Control
              value={neighbourhood}
              onChange={(ev) => setNeighbourhood(ev.target.value)}
              />
          </Form.Group>
        </Col>        
      </Row>
      <Row>
        <Col xs={12} md={2}>
          <Form.Group className="mb-3" style={{ paddingTop : 30 }}>
            <Button variant="success" onClick={search}>Procurar</Button>
          </Form.Group>
        </Col>
      </Row>
      { resultHtml ? resultHtml : "" }
      <Row>
        <Col style={{
          paddingTop : 15
        }}>
          { loading ? <Spinner /> : samplesHtml}
        </Col>
      </Row>
      <NotificationContainer/>
    </Container>
  );
}

export default App;
